import React from "react"
import styled from 'styled-components'

//Helpers
import Title from "../helpers/Title"

//Components
import Layout from "../components/Layout"
import Language from "../components/Language"
import Seo from "../components/seo"
import FAQBox from "../components/FAQBox"

//Assets
import faq from '../assets/images/faq.png'

//Textos
import texts from '../components/FAQ/content'

const Videos = () => (
  <Layout>
    <Seo title="FAQ" />
    <FaqHeader>
      <HeaderContent>
        <HeaderTitle>FAQ</HeaderTitle>
        <HeaderText>{Language(texts).texto}</HeaderText>
      </HeaderContent>
      <HeaderImage src={faq} alt='' />
    </FaqHeader>
    {Language(texts).duvidas.map(
        (item, index) => {
          return (
            <FAQBox
              title={item.titulo}
              text={item.texto}
              img={item.img}
              inverse={index % 2 === 0 ? true : false }
              key={index}
            />
          )
        }
      )}
  </Layout>
)

export default Videos

const FaqHeader = styled.header`
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 768px) {
    padding-top: 24px;
  }
`
const HeaderContent = styled.div`
  width: 50%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`
const HeaderTitle = styled(Title)`
  font-size: 4rem;
  text-align: left;

  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
`
const HeaderText = styled.p`
  font-family: var(--primaryLight);
  font-size: 2rem;
  color: #929292;
  line-height: 2.5rem;

  @media screen and (max-width: 768px) {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
`
const HeaderImage = styled.img`
  width: 40%;

  @media screen and (max-width: 768px) {
    display: none;
  }
`