import React from 'react';
import styled from 'styled-components'

const FAQBox = ({ title, text, img, inverse }) => {
  const FAQBoxContainer = styled.section`
    background: #e6e6e6;
    margin: 4rem 0;
  `

  const FAQBoxContent = styled.div`
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
    padding: 0 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 768px) {
      padding: 0;
      flex-direction: column;
    }
  `

  const FAQBoxBox = styled.div`
    padding-right: ${(inverse ? '0' : '2rem')};
    padding-left: ${(inverse ? '2rem' : '0')};

    @media screen and (max-width: 768px) {
      margin: 0 1.5rem;
      padding: 0;
    }
  `
  const Title = styled.h3`
    margin-bottom: 1rem;
    font-family: var(--bold);
    font-size: 1.5rem;
    text-align: ${(inverse ? 'end' : 'start')};
    line-height: 2rem;

    @media screen and (max-width: 768px) {
      font-size: 1.25rem;
      text-align: start;
    }
  `
  const Text = styled.p`
    font-family: var(--regular);
    font-size: 1rem;
    text-align: ${(inverse ? 'end' : 'start')};
    line-height: 1.5rem;

    @media screen and (max-width: 768px) {
      text-align: start;
      margin-bottom: 2rem;
    }
  `

  const FAQBoxImage = styled.img`
    width: 40%;
    order: ${(inverse ? '-1' : '1')};

    @media screen and (max-width: 768px) {
      width: 100%;
      order: -1;
      margin-bottom: 1rem;
    }
  `
  return(
    <FAQBoxContainer>
      <FAQBoxContent>
        <FAQBoxBox>
          <Title>{title}</Title>
          <Text>{text}</Text>
        </FAQBoxBox>
        <FAQBoxImage src={img} alt='' />
      </FAQBoxContent>
    </FAQBoxContainer>
  )
}

export default FAQBox